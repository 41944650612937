import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link, Router, Switch } from "react-router-dom";
import axios from "axios";
import Home from "./Home";
import Track from "./Track";
import Artist from "./Artist";
import ArtistPage from "./ArtistInfo";
import CommentBoard from "./Board";
import Playlist from "./Playlist";
import PLPage from "./PlaylistInfo";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/trackid/:trackid" Component={Track}></Route>
        <Route path="/artist" element={<Artist />}></Route>
        <Route path="/artistid/:artistid" element={<ArtistPage />}></Route>
        <Route path="/playlist" element={<Playlist />}></Route>
        <Route path="/playlistid/:playlistid" element={<PLPage />}></Route>
        <Route path="/board" Component={CommentBoard}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
