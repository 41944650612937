import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import '../App.css'

function CommentBoard() {
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalComments, setTotalComments] = useState(0);
  const [loading, setLoading] = useState(true);
  const [newPosting, setNewPosting] = useState(true);
  const commentsPerPage = 5;

  // 데이터 불러오기
  useEffect(() => {
    setLoading(true);
    
    axios
      .get(`http://localhost:5000/comments?page=${currentPage}&limit=${commentsPerPage}`)
      .then((response) => {
        console.log(response.data);
        setComments(response.data.comments || []);
        setTotalComments(response.data.total || 0);
        setLoading(false);
        console.log(comments);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [currentPage, newPosting]);

  // 페이지 변경
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div 
      style={{display:"flex", flexDirection:"column", 
              justifyContent:"center", alignItems:"center",
              margin:5}}>
        <div className="container"
        style={{
        }}>
        {/* 상단메뉴바 */}
        <div style={{ display: "flex", justifyContent: "left", alignItems: "start", fontSize: 14 }}>
        
        {/* Home Link */}
        <Link to='/' reloadDocument className="nav-link">
          Home
        </Link>

        {/* Artist Link */}
        <Link to='/artist' reloadDocument className="nav-link">
          Artist
        </Link>

        {/* Playlist Link */}
        <Link to='/playlist' reloadDocument className="nav-link">
          Playlist
        </Link>

        {/* Board Link */}
        <Link to='/board' reloadDocument className="nav-link">
          Board
        </Link>
        </div>
        </div>
      <h1>게시판</h1>

      {/* 글 작성 폼 */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const name = e.target.name.value;
            const comment = e.target.comment.value;
            if (!name || !comment) return;

            axios
              .post('http://localhost:5000/comments', { name, comment })
              .then(() => {
                setCurrentPage(1); // 글 작성 후 첫 페이지로 이동
                e.target.reset(); // 입력 필드 리셋
                // 페이지 이동만으로 useEffect가 재실행되어 자동으로 데이터가 불러와짐
                setNewPosting(!newPosting)
              })
              .catch((error) => {
                console.error('Error posting comment:', error);
              });
          }}
        >
        <div
          style={{
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            width: "500px",
            display: "flex",
            flexDirection:"column",
            justifyContent: "start",
            alignItems:"start",
            gap: "0.4em",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add this line for box shadow
            margin:0,
            backgroundColor:"#eee"
          }}>
          <input
            type="text"
            name="name"
            placeholder="이름"
            style={{
              width: 200,
              border: 'none',
              zIndex: "1",
              outline: "none",
              borderRadius: 5,
            }}
          />
          <textarea
            name="comment"
            placeholder="내용"
            style={{
              height: 300,
              width: 480,
              border: 'none',
              zIndex: "1",
              outline: "none",
              resize: "none",
              borderRadius: 5,
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            width: "100px",
            display: "flex",
            flexDirection:"center",
            justifyContent: "center",
            alignItems:"start",
            gap: "0.4em",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add this line for box shadow
            margin:0,
            backgroundColor:"#eee"
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#fff'; // hover 시 좀 더 밝은 색상
            // e.target.style.boxShadow = '0 6px 12px rgba(199, 125, 255, 0.6)';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#ddd';
            // e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
          }}
        >
          작성
        </button>
      </form>

      {/* 게시글 목록 */}
      {loading ? (
        <p>게시글을 불러오는 중입니다...</p>
      ) : (
        <ul>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <li key={comment[0]}>
                <strong>{comment[1]}</strong>: {comment[2]}
              </li>
            ))
          ) : (
            <p>게시물이 없습니다.</p>
          )}
        </ul>
      )}

      {/* 페이지 번호 */}
      {totalComments > 0 && (
        <Pagination
          commentsPerPage={commentsPerPage}
          totalComments={totalComments}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

function Pagination({ commentsPerPage, totalComments, paginate, currentPage }) {
  const totalPages = Math.ceil(totalComments / commentsPerPage);

  return (
    <nav>
      <ul style={{ listStyleType: 'none', display: 'flex', justifyContent: 'center', padding: '0' }}>
        {/* 이전 버튼 (첫 페이지에서는 표시하지 않음) */}
        {currentPage > 1 && (
          <li style={{ margin: '0 5px' }}>
            <button
              onClick={() => paginate(currentPage - 1)}
              style={{
                padding: "8px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                width: "500px",
                display: "flex",
                flexDirection:"column",
                justifyContent: "start",
                alignItems:"start",
                gap: "0.4em",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add this line for box shadow
                margin:0,
                backgroundColor:"#eee"
              }}
            >
              이전
            </button>
          </li>
        )}

        {/* 다음 버튼 (마지막 페이지에서는 표시하지 않음) */}
        {currentPage < totalPages && (
          <li style={{ margin: '0 5px' }}>
            <button
              onClick={() => paginate(currentPage + 1)}
              style={{
                padding: '12px 20px',
                borderRadius: '15px',
                backgroundColor: '#eee', // 파스텔 퍼플톤
                color: '#fff',
                fontSize: '16px',
                fontFamily: 'Courier New, monospace', // 통일된 폰트
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
              }}
            >
              다음
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}

// function Pagination({ commentsPerPage, totalComments, paginate, currentPage }) {
//   const pageNumbers = [];

//   // 총 페이지 수 계산, 최소한 페이지 번호는 1
//   const totalPages = Math.ceil(totalComments / commentsPerPage);
  
//   for (let i = 1; i <= totalPages; i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <nav>
//       <ul style={{ listStyleType: 'none', display: 'flex' }}>
//         {pageNumbers.length > 0 ? (
//           pageNumbers.map((number) => (
//             <li key={number} style={{ margin: '0 5px' }}>
//               <button
//                 onClick={() => paginate(number)}
//                 disabled={currentPage === number}
//               >
//                 {number}
//               </button>
//             </li>
//           ))
//         ) : (
//           <li>
//             <button disabled>1</button>
//           </li>
//         )}
//       </ul>
//     </nav>
//   );
// }

export default CommentBoard;